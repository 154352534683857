import React, { useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Confirmation from './Confirmation';
import ProgressTracker from './ProgressTracker';

const FormWizard = () => {
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const updateFormData = (stepData) => {
    setFormData(prevData => ({ ...prevData, ...stepData }));
  };

  const nextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
      navigate(`/step${currentStep + 1}`);
    } else {
      navigate('/confirmation');
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      navigate(`/step${currentStep - 1}`);
    }
  };

  const submitForm = async () => {
    try {
      // Ensure email is included in the form data
      if (!formData.businessEmail) {
        console.error('Business email is missing from form data');
        // Handle error (e.g., show error message to user)
        return;
      }

      const response = await fetch('https://wellgenpro-seo-form-node.onrender.com/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          email: formData.businessEmail // Ensure email is explicitly set
        }),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        navigate('/confirmation');
      } else {
        console.error('Form submission failed');
        navigate('/confirmation');
        // Handle error (e.g., show error message to user)
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const renderStep = (Step) => {
    return (
      <Step
        formData={formData}
        updateFormData={updateFormData}
        nextStep={nextStep}
        prevStep={prevStep}
        submitForm={submitForm}
      />
    );
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
      <div className="w-full lg:w-1/4 bg-blue-600 text-white p-4 lg:p-8 flex flex-col">
        <div className="mb-6 lg:mb-12">
          <img src='/wellgenprologo.png' alt="wellgenpro Logo" className="h-[35px] mb-5" />
          <h2 className="text-lg font-semibold">Onboarding Form</h2>
        </div>
        <ProgressTracker currentStep={currentStep} />
        <div className="mt-auto pt-8 text-xs lg:text-sm text-blue-200">
          All rights reserved @WellGenPro
        </div>
      </div>
      <div className="w-full lg:w-3/4 p-4 lg:p-8 overflow-auto flex flex-col">
        <div className="max-w-3xl mx-auto w-full">
          <Routes>
            <Route path="/step1" element={renderStep(Step1)} />
            <Route path="/step2" element={renderStep(Step2)} />
            <Route path="/step3" element={renderStep(Step3)} />
            <Route path="/step4" element={renderStep(Step4)} />
            <Route path="/step5" element={renderStep(Step5)} />
            <Route path="/confirmation" element={<Confirmation formData={formData} />} />
            <Route path="/" element={<Navigate to="/step1" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default FormWizard;